<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <el-button
            plain
            class="add-column-btn bbox"
            @click="gotoDetail('')"
            type="primary"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col>
        <!-- <el-col> -->
        <el-select
          v-model="supplierId"
          placeholder="请选择运营商"
          @change="selectCheck"
          clearable
          v-show="this.statu == 1"
        >
          <el-option
            v-for="item in idList"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
            <!-- v-show="item.type == it.value||!selectIdsArr.includes(it.value)" -->
          </el-option>
        </el-select>
        <!-- </el-col> -->
        <!-- <el-col>
                    <div class="flex align-center justify-end header-search-box">
                        <el-input
                                placeholder="查询条件"
                                clearable
                                v-model="searchInput"
                                class="header-search-input ml10"
                        >
                            <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    class="textblue searchBtn"
                                    @click="search()"
                            ></el-button>
                        </el-input>
                    </div>
                </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="站长ID" align="center" prop="id">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="站长姓名" align="center" prop="adminName">
          <template slot-scope="scope">
            <span>{{ scope.row.adminName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="站长登录账号"
          align="center"
          prop="adminAccount"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.adminAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" align="center" prop="id" v-if="false">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="站长密码" align="center" prop="id">
          <template slot-scope="scope">
            <span>{{ scope.row.adminPassword }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="站长身份证号"
          align="center"
          prop="adminCardId"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.adminCardId }}</span>
          </template>
        </el-table-column>

        <el-table-column label="联系电话" align="center" prop="adminPhone">
          <template slot-scope="scope">
            <span>{{ scope.row.adminPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="重置密码"
              placement="bottom"
            >
              <i
                class="el-icon-lock textblue f16 ml20"
                @click="reset(scope.row)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看详情"
              placement="bottom"
            >
              <el-button
                type="primary"
                icon="el-icon-info"
                circle
                @click="gotoDetail(scope.row.id, scope.row.userCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      statu: "",
      tableData: [],
      idList: [],
      loading: true,
      id: "",
      selectIdsArr: [],
      supplierId: "",
      ids: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        keyvalue: "",
        //查询条件
        condition: {
          id: "",
          supplierId: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    // this.getUserInfo();
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //  selectIdsArrSelect() {
    //   this.selectIdsArr = []
    //   for (const item of this.formData.pointSettingDtoList) {
    //     if (item.type) {
    //       this.selectIdsArr.push(item.type)
    //     }
    //   }
    // },
    //条件查询
    // search(){
    // this.queryInfo.condition.id = this.searchInput;
    // this.getListData();
    // },

    //状态改变事件
    // changeStatus(row) {

    //   var that=this;
    // //   alert()
    //  that.repairsStatus=1;
    //   that.$http.post("/repairsInfo/save", {repairsStatus:1,id:row.id}
    //   ).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "保存成功",
    //         showClose: true,
    //       });
    //     that.getListData();
    //   }).catch(function(error){
    //     //   console.log("---")
    //       that.$notify.info({
    //         title: "提示",
    //         message: "保存失败",
    //         showClose: true,
    //       });
    //   })

    // },
    //重置密码
    async reset(row) {
      var that = this;
      const confirmResult = await this.$confirm("请确认重置密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() =>
        that.$http
          .post("/userAdmin/save", { id: row.id, adminPassword: "123456" })
          .then(function(response) {
            if (response.data.code == 200) {
              that.$message({
                message: "重置成功！！！",
                duration: 1500,
                type: "success"
              });
              that.getListData();
            } else {
              that.$message({
                message: "重置失败！！！",
                duration: 1500,
                type: "error"
              });
            }
          })
      );
    },
    selectCheck(value) {
      // var data = window.localStorage.loginName
      // console.log(data)
      // console.log(JSON.parse(data))
      // data=JSON.parse(data)
      //   console.log(data.value)
      let that = this;
      that.queryInfo.condition.supplierId = value;
      that.$http
        .post("/userAdmin/list", that.queryInfo)
        .then(function(response) {
          // console.log(response)
          if (response.data.code == 200) {
            // console.log("-----")
            // that.$message({
            // type: 'success',
            // message: '处理成功!'})
            // that.total = response.data.data.count;
            // that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/managerM/details",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/userAdmin/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      var that = this;

      that.statu = localStorage.getItem("employeeType");
      console.log(that.statu);
      that.ids = localStorage.getItem("ids");
      console.log(that.ids);
      // console.log(statu)
      // console.log(ids)
      if (that.statu == 1) {
        console.log("---------");
        that.$http
          .post("userAdmin/list", {
            currPage: that.queryInfo.currPage,
            pageSize: that.queryInfo.pageSize,
            condition: {}
          })
          .then(function(response) {
            if (response.data.code == 200) {
              // console.log("------")
              // console.log(response.data.data.data)
              that.total = response.data.data.count;
              that.tableData = response.data.data.data;
              that.getIdList();
              console.log("111");
              console.log(that.tableData);
            }
          });
      } else {
        console.log("---------");
        that.$http
          .post("userAdmin/list", {
            currPage: that.queryInfo.currPage,
            pageSize: that.queryInfo.pageSize,
            condition: {
              supplierId: that.ids
            }
          })
          .then(function(response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.tableData = response.data.data.data;
              that.getIdList();
              // console.log("222");
            }
          });
      }
    },

    getIdList() {
      let that = this;
      let tempList = [];
      that.tableData.forEach(item => {
        tempList.push(item.supplierId);
      });
      tempList = [...new Set(tempList)];
      that.idList = tempList.map(item => {
        return {
          value: item
        };
      });
    },

    //  getListData() {
    //   // console.log(this.statu)
    //   // 获取列表
    //   var that = this;
    //   var data = that.queryInfo;

    // },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
