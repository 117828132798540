import { render, staticRenderFns } from "./ManagerM.vue?vue&type=template&id=12a00247&scoped=true"
import script from "./ManagerM.vue?vue&type=script&lang=js"
export * from "./ManagerM.vue?vue&type=script&lang=js"
import style0 from "./ManagerM.vue?vue&type=style&index=0&id=12a00247&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a00247",
  null
  
)

export default component.exports